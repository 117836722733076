import { IPageHeader } from "@interface/propsInterface";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { ISPageHeaderProps } from "@interface/styledComponentInterface";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import {
  color,
  screenBreakPoint,
  fontWeight,
  fontSize,
} from "@styles/styleVariables";
import { motion } from "framer-motion";
import { fadeIn, headerBlackBar, headerRevealv1, parentAnimation, pushItemDown, pushItemLeft, pushItemUp } from "@styles/animations";

export const PageHeader = ({
  name,
  description,
  coverImage,
  coverImageBlur,
  coverImageGreyScale,
}: IPageHeader) => {
  return (
    <SPageHeader
      coverImageBlur={coverImageBlur}
      coverImageGreyScale={coverImageGreyScale}
    >
      <motion.div
        className="page-header-content-wrapper"
        initial="before"
        animate="after"
        variants={parentAnimation(0.01)}
      >
        <motion.h1 variants={pushItemLeft}>{name}</motion.h1>
        <motion.p variants={pushItemUp}>{description}</motion.p>
      </motion.div>
      <div className="page-header-image-wrapper">
        <GatsbyImage
          image={coverImage}
          alt={name}
          className="page-header-image"
        ></GatsbyImage>
        <div className="page-header-image-overlay"></div>
      </div>
    </SPageHeader>
  );
};

const SPageHeader = styled.div<ISPageHeaderProps>`
  min-height: 66vh;
  max-height: 75vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  padding: 5rem 1rem;

  .page-header-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    .page-header-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      filter: blur(${(props) => (props.coverImageBlur ? "5px" : "0px")})
        grayscale(${(props) => (props.coverImageGreyScale ? "1" : "0")});
    }

    .page-header-image-overlay {
      background: ${convertHexToRGBA(color.oxfordBlue, 0.75)};
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .page-header-content-wrapper {
    z-index: 2;
    width: 33%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media (max-width: ${screenBreakPoint.md}) {
      width: 50%;
    }

    @media (max-width: ${screenBreakPoint.xs}) {
      width: 100%;
    }

    h1 {
      color: ${color.sunglow};
      font-size: ${fontSize.lg};
      font-weight: ${fontWeight.semiBold};
      text-align: center;
    }

    p {
      margin: 2rem 0rem;
      color: ${color.culture};
      font-weight: ${fontWeight.light};
      text-align: center;
    }
  }
`;
